;(function($){
	$.fn.extend({
					donetyping: function(callback,timeout){
						timeout = timeout || 1e3; // 1 second default timeout
						var timeoutReference,
								doneTyping = function(el){
									if (!timeoutReference) return;
									timeoutReference = null;
									callback.call(el);
								};
						return this.each(function(i,el){
							var $el = $(el);
							// Chrome Fix (Use keyup over keypress to detect backspace)
							// thank you @palerdot
							$el.is(':input') && $el.on('keyup keypress paste',function(e){
								// This catches the backspace button in chrome, but also prevents
								// the event from triggering too preemptively. Without this line,
								// using tab/shift+tab will make the focused element fire the callback.
								if (e.type=='keyup' && e.keyCode!=8) return;

								// Check if timeout has been set. If it has, "reset" the clock and
								// start over again.
								if (timeoutReference) clearTimeout(timeoutReference);
								timeoutReference = setTimeout(function(){
									// if we made it here, our timeout has elapsed. Fire the
									// callback
									doneTyping(el);
								}, timeout);
							}).on('blur',function(){
								// If we can, fire the event since we're leaving the field
								doneTyping(el);
							});
						});
					}
				});
})(jQuery);

var ercasAddresses = {
	onSubmit: false,

	init: function(){
		'use strict';
		var api = this;

		if($('[data-ea-onsubmit="true"]').length > 0) {
			api.onSubmit = true;
		}

		if($('[name="ea-country"]').length > 0) {
			$('[name="ea-zip"]').attr('readonly', true);
			$('[name="ea-zip"]').parents('form').addClass('no-zip');
		}

		// if result item is open load google maps
		$('#ea-results').on('show.bs.collapse', function (e) {
			var currentIFrame = $(e.target).closest('.panel').find('iframe');
			if (!currentIFrame.attr('src')) {
				currentIFrame.attr('src', currentIFrame.data("src"));
			}
		});

		if(!api.onSubmit) {
			$('[name="ea-zip"]').parents('form').on('submit', function (e) {
				e.preventDefault();
			});


			$('[name="ea-zip"]').donetyping(function () {
				if ($('[name="ea-category"]').length > 0) {
					var category = $('[name="ea-category"]').val();
				} else {
					var category = '*';
				}
				if ($('[name="ea-country"]').length > 0) {
					var country = $('[name="ea-country"]').val();
				} else {
					var country = '*';
				}
				api.searchByZip($('[name="ea-zip"]').val(), $('[name="ea-rage"]').val(), category, country);
			});
			$('[name="ea-country"]').change(function () {
				$('[name="ea-zip"]').val('');
				$('#ea-count').html('0');
				$('#ea-results').html('');
			});
			$('[name="ea-rage"], [name="ea-category"], [name="ea-country"]').change(function () {
				if ($('[name="ea-category"]').length > 0) {
					var category = $('[name="ea-category"]').val();
				} else {
					var category = '*';
				}
				if ($('[name="ea-country"]').length > 0) {
					var country = $('[name="ea-country"]').val();
				} else {
					var country = '*';
				}
				api.searchByZip($('[name="ea-zip"]').val(), $('[name="ea-rage"]').val(), category, country);
			});
		} else {
			var option = JSON.parse($('#ea-option-json').html());
			$('[name="ea-country"]').change(function () {
				$('[name="ea-zip"]').val('');
				$('.rm-ea-val-icon').removeClass('show');
				$('#ea-count').html('0');
				$('#ea-results').html('');
				if ($('[name="ea-country"]').length > 0) {
					var country = $('[name="ea-country"]').val();
				} else {
					var country = '*';
				}
				if(api.zipSearchAllowed(option.zipSearchAllow,parseInt(country))) {
					$('[name="ea-zip"]').attr('readonly',false).attr('required','required');
					if($('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
						$('[name="ea-zip"]').parents('form').removeClass('no-zip');
					}
				} else {
					$('[name="ea-zip"]').attr('readonly',true).removeAttr('required');
					$('[name="ea-country"]').parents('form').validator('validate');
					// $('.has-error.has-danger').removeClass('has-error has-danger');
					// $('.with-errors').remove();
					// $('[type="submit"]').removeClass('disabled');
					if(!$('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
						$('[name="ea-zip"]').parents('form').addClass('no-zip');
					}
				}
			});

			$('[data-ea-onsubmit="true"]').on('submit', function (e) {
				e.preventDefault();
				if ($('[name="ea-category"]').length > 0) {
					var category = $('[name="ea-category"]').val();
				} else {
					var category = '*';
				}
				if ($('[name="ea-country"]').length > 0) {
					var country = $('[name="ea-country"]').val();
				} else {
					var country = '*';
				}

				if(!$('[name="ea-zip"]').parents('form').hasClass('no-zip') && $('[name="ea-zip"]').val() != '' || $('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
					api.searchByZip($('[name="ea-zip"]').val(), $('[name="ea-rage"]').val(), category, country);
				}

			});
		}

		$('.rm-ea-val-icon').on('click touchend', function(e){
			e.preventDefault();
			api.searchClickRmButton();
		});
	},
	searchClickRmButton : function(){
		var api = this;
		$('[name="ea-zip"]').val('');
		$('.rm-ea-val-icon').removeClass('show');
		$('#ea-count').html('0');
		$('#ea-results').html('');
		$('[type="submit"]').addClass('disabled');
		
		// setTimeout(function(){
		// 	$('#PageLoader').css('z-index', '999999');
		// 	if (typeof TweenMax !== 'undefined') {
		// 		TweenMax.to($('#PageLoader'), 1, {opacity: 1}).eventCallback("onComplete", function () {
		// 				api.showAddresses({});
		// 		});
		// 	} else {
		// 		api.toggleSpinner(true);
		// 		api.showAddresses({});
		// 	}
		// }, 500);
	},
	validateForm: function() {
		'use strict';

		var error = false;

		$('[name="ea-country"], [name="ea-category"]').each(function() {
			if($(this).attr('required')) {
				var parentWrap = $(this).parents('.powermail_fieldwrap').first();
				if($(this).val() == '*') {
					error = true;
					if(!parentWrap.hasClass('error')) {
						parentWrap.addClass('error');
					}
				} else {
					if(parentWrap.hasClass('error')) {
						parentWrap.removeClass('error');
					}
				}
			}
		});

		console.log(error);

		if (error) {
			return false;
		} else {
			return true;
		}
	},
	searchByZip: function(inputValue, distanceValue, categoryValue, countryValue){
		var option = JSON.parse($('#ea-option-json').html());

		if(countryValue == '54') {
			if ($.isNumeric(inputValue) && inputValue.length < 5) {
				switch (inputValue.length) {
					case 1:
						inputValue = inputValue * 10000;
						break;
					case 2:
						inputValue = inputValue * 1000;
						break;
					case 3:
						inputValue = inputValue * 100;
						break;
					case 4:
						inputValue = inputValue * 10;
				}
			}
		}

		var api = this;
		$postData = {
			type: 22222,
			tx_ercasaddresses_pi1: {
				value: inputValue,
				distance: distanceValue,
				category: categoryValue,
				country: countryValue,
				range: false,
				withform: option.withform,
				pid: option.pid,
				sorting:option.sorting,
				showFormAfter: option.showFormAfter,
				noForm: option.noForm
			}
		};
		// console.log('test',api.validateForm());
		if(!api.validateForm()) {
			return false;
		}

		if(api.zipSearchAllowed(option.zipSearchAllow,parseInt(countryValue))) {
			if(api.zipSearchAllowed(option.zipSearchRange,parseInt(countryValue))){
				$postData.tx_ercasaddresses_pi1.range = true;
			}
			$('[name="ea-zip"]').attr('readonly',false);
			if($('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
				$('[name="ea-zip"]').parents('form').removeClass('no-zip');
			}
			if(inputValue !== '') {
				$('#PageLoader').css('z-index', '999999');
				if (typeof TweenMax !== 'undefined') {
					TweenMax.to($('#PageLoader'), 1, {opacity: 1}).eventCallback("onComplete", function () {

						$.post(window.location.pathname, $postData, function (data) {
							api.showAddresses(JSON.parse(data));
						});
					});
				} else {
					api.toggleSpinner(true);
					$.post(window.location.pathname, $postData, function (data) {
						api.showAddresses(JSON.parse(data));
					});
				}
			} else {
				$postData.tx_ercasaddresses_pi1.range = false;
				api.toggleSpinner(true);
				$.post(window.location.pathname, $postData, function (data) {
					api.showAddresses(JSON.parse(data));
				});
			}
		} else {
			$('[name="ea-zip"]').attr('readonly',true);
			if(!$('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
				$('[name="ea-zip"]').parents('form').addClass('no-zip');
			}
			$('#PageLoader').css('z-index', '999999');
			if (typeof TweenMax !== 'undefined') {
				TweenMax.to($('#PageLoader'), 1, {opacity: 1}).eventCallback("onComplete", function () {

					$.post(window.location.pathname, $postData, function (data) {
						api.showAddresses(JSON.parse(data));
					}).fail(function(){
							$('#ea-results').html('');
							var counterResults = 0;
							$('#ea-count').html(counterResults);

							if($('#error-address').hasClass('hidden')) {
								$('#error-address').removeClass('hidden');
							}

							TweenMax.to($('#PageLoader'), 1, {opacity: 0}).eventCallback("onComplete", function () {
								$('#PageLoader').css('z-index', '-1');
							});

					});
				});
			} else {
				api.toggleSpinner(true);
				$.post(window.location.pathname, $postData, function (data) {
					api.showAddresses(JSON.parse(data));
				}).fail(function(){
					$('#ea-results').html('');
					var counterResults = 0;
					if($('#error-address').hasClass('hidden')) {
						$('#error-address').removeClass('hidden');
					}
					$('#ea-count').html(counterResults);
					api.toggleSpinner(false);
				});
			}
		}

		if(inputValue !== '') {
			if(!$('.rm-ea-val-icon').hasClass('show')) {
				$('.rm-ea-val-icon').addClass('show');
			}
		} else {

		}


	},
	zipSearchAllowed: function(options, country) {
		var match = false;
		$.each(options, function(key, value){
			if(value === country) {
				match = true;
			}
		});
		if(match) {
			return true;
		} else {
			return false;
		}
	},
	showAddresses: function (ajaxData) {
		'use strict';
		$('#ea-results').html('');
		var counterResults = 0;



		$.each(ajaxData, function (key, value) {
			$('#ea-results').append(value.html);
			counterResults++;
		});

		if(counterResults > 0) {
			if(!$('#error-address').hasClass('hidden')) {
				$('#error-address').addClass('hidden');
			}
		} else {
			if($('#error-address').hasClass('hidden')) {
				$('#error-address').removeClass('hidden');
			}
		}

		$('#ea-count').html(counterResults);

		if($('#ea-referrer').length > 0) {
			$('#ea-referrer').val(document.referrer);
		}
		if(typeof TweenMax !== 'undefined') {
			TweenMax.to($('#PageLoader'), 1, {opacity: 0}).eventCallback("onComplete", function () {
				$('#PageLoader').css('z-index', '-1');
			});
		} else {
			this.toggleSpinner(false);
		}


	},
	toggleSpinner: function(visibility) {
		var spinner = $('#ea-spinner');

		if($('#ea-spinner').length > 0) {
			if (visibility) {
				if(!spinner.hasClass('show')) {
					spinner.addClass('show');
				}
			} else {
				if(spinner.hasClass('show')) {
					spinner.removeClass('show');
				}
			}
		}
	}

};


if (typeof jQuery === 'undefined') {
	// jQuery is not loaded
	console.warn('jQuery is not supported! The JS for ercas addresses extension could not be load!');
} else {
	// jQuery is loaded
	$(document).ready(function(){
		'use strict';
		if($('#ea-start').length > 0) {
			ercasAddresses.init();
		}
	});
}